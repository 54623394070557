<template>
  <section class="review-pack-modal">
    <hgroup class="review-pack-modal__title">
      <h2>
        {{$t('frac_pack.instant_sell')}}
      </h2>
      <p>{{$t('frac_pack.instant_sell_text')}}</p>
    </hgroup>
    
    <ul class="review-pack-modal__list">
      <li 
        class="review-pack-modal__list-item"
        v-for="card in cards"
        :key="card.id"
      >
        <div class="review-pack-modal__left-col">
          <img 
            class="review-pack-modal__card-thumbnail"
            :src="card.asset.img_front" :alt="card.asset.name"
          />
          <ButtonV2
            v-if="isSelected(card.id)"
            wide
            size="small"
            version="primary"
            testId="btn-fracpack-selected"
            icon="check"
            :label="$t('frac_pack.selected')"
            @onClick="removeCard(card.id)"
          />
          <ButtonV2
            v-else
            wide
            version="secondary"
            size="small"
            testId="btn-fracpack-select"
            :label="$t('frac_pack.select')"
            @onClick="addCard(card.id)"
          />
        </div>
        <div class="review-pack-modal__right-col">
          <h3 class="text-n-lg leading-md font-semibold">{{card.asset.name}}</h3>
          <ul class="review-pack-modal__card-info">
            <li>
              <div>{{$t('frac_pack.card_quantity')}}: </div>
              <div><strong>{{ numberFormat(currentFracpackDefinition.fraction_per_card * 100, 2) }}%</strong></div>
            </li>
            <li>
              <div>{{$t('frac_pack.usd_value')}}: </div>
              <div><strong>{{ numberFormat(card.asset_price, 2, false, true) }}</strong></div>
            </li>
            <li>
              <div>{{$t('frac_pack.card_price')}}: </div>
              <div><strong>{{ numberFormat(card.asset.price_usd, 2, false, true)  }}</strong></div>
            </li>
            <li>
              <div>{{$t('frac_pack.sell_price')}}: </div>
              <div><strong class="label-highlight">{{ numberFormat(card.asset_price, 2, false, true) }}</strong></div>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <div class="review-pack-modal__action">
      <ButtonV2
        wide
        size="large"
        version="primary"
        testId="btn-fracpack-sell"
        :label="$tc('frac_pack.sell_btn_label', cards_count, { count: cards_count === cards.length ? 'All' : cards_count })"
        @onClick="emitCardsSelected"
      />
    </div>
  </section>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import {mapGetters} from 'vuex';
export default {
  name: 'ReviewFracpackModal',
  components: { ButtonV2 },
  props: {
    cards: {
      type: Array,
      validator: (arr) => arr.map(item => item.__typename === 'FracPackCard')
    }
  },
  data() {
    return {
      cards_selected: []
    };
  },
  computed: {
    ...mapGetters('fracpack', [
      'currentFracpackDefinition',
      'fracpackTrackingData',
      'fracpackAssetTrackingData'
    ]),

    cards_count() {
      return this.cards_selected.length;
    },
  },
  methods: {
    isSelected(card_id) {
      return this.cards_selected.includes(card_id);
    },
    addCard(card_id) {
      this.cards_selected.push(card_id);
      this.$store.dispatch('events/track', {
        event: 'FRAC_PACK_ASSET_INSTA_SELL',
        variables: {
          ...this.fracpackAssetTrackingData,
        },
      });
    },
    removeCard(card_id) {
      const index = this.cards_selected.indexOf(card_id);
      if (index > -1) {
        this.cards_selected.splice(index, 1);
      }
    },
    emitCardsSelected() {
      const fracpackDecision = this.cards.map(card => {
        return {
          fracpack_card_id: card.id,
          sell: this.cards_selected.includes(card.id)
        };
      });

      if (this.cards_count > 0) {
        this.$store.dispatch('events/track', {
          event: 'FRAC_PACK_SELL_ASSETS',
          variables: {
            ...this.fracpackTrackingData,
            assets_sold: this.cards_count,
          },
        });
      } else {
        this.$store.dispatch('events/track', {
          event: 'FRAC_PACK_KEEP_ALL_ASSETS',
          variables: {
            ...this.fracpackTrackingData,
          },
        });
      }

      this.$store.dispatch('fracpack/beforeDecide', { 
        fracpackDecision,
      });

      this.$store.dispatch('events/track', {
        event: 'FRAC_PACK_ASSETS_CHECKOUT_VIEWED',
        variables: {
          ...this.fracpackTrackingData,
        },
      });
    },
  }
};
</script>

<style scoped>
.review-pack-modal {
  @apply px-n-2xl py-n-3xl bg-background-page;
  position: relative;
  padding-bottom: 92px;
}
.is-screen-lg-up .review-pack-modal {
  width: 390px;
  height: 616px;
}
.review-pack-modal__title {
  @apply mb-n-4xl;
}
.review-pack-modal__title h2{
  @apply text-n-4xl font-bold;
}
.review-pack-modal__title h2 a{
  @apply text-n-2xl font-normal;
}
.review-pack-modal__title p{
  @apply text-n-lg font-medium leading-md;
}
.review-pack-modal__list {
  @apply list-none overflow-y-auto pb-n-3xl;
  height: 390px;
}
.is-screen-md-down .review-pack-modal__list {
  height: calc(100vh - 260px);  
}
.review-pack-modal__list-item {
  @apply flex justify-between mb-n-4xl;
}
.review-pack-modal__list-item:last-of-type {
  margin-bottom: 0;
}
.review-pack-modal__left-col {
  @apply mr-n-4xl;
  width: 100px;
}
.review-pack-modal__card-thumbnail {
  @apply mb-n-xl;
  width: 100%;
}
.review-pack-modal__right-col {
  @apply flex-1 pr-n-md;
}
.review-pack-modal__card-info li{
  @apply flex justify-between items-center py-n-md text-n-md font-medium;
  border-bottom: solid 1px;
  border-color: #00000040;
}
.dark .review-pack-modal__card-info li {
  border-color: #FFFFFF70;
}
.review-pack-modal__card-info li:last-of-type {
  border: none;
}
.review-pack-modal__action {
  @apply px-n-4xl py-n-4xl bg-background-page;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 12px #00000030;
}
.dark .review-pack-modal__action {
  box-shadow: 0px 0px 12px #ffffff75;
}

.is-screen-md-down .review-pack-modal {
  height: 100vh;
}

.label-highlight {
  color: var(--colors-text-active-2);
}
</style>